import React, {useState} from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux'
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import PrintIcon from '@mui/icons-material/Print';

import { Button, Divider } from '@material-ui/core';
import {businessname, bstreet, bcity, bemail, bphone} from '../models/European'
import logo from '../assets/image/logo.png'


const SearchEstimate = () => {

    const [inputfiltro, setInputFiltro] = useState('')
    const [filterPhone, setFilterPhone] = useState(null)

    const searchByPhone = useSelector(state => state.estimates.estimates)



    const  onChangeHandlerInput = (event) => {
        setInputFiltro(event.target.value);   
    }

    const handlerSubmit = () => {
        setFilterPhone(searchByPhone.filter(item => item.Phone === inputfiltro))        
    }

   const print = () => {
      window.print();
    }
    return (
        <>
        <Container>
            <Row>
                <Col className='mt-2'>
                    <Row style={{margin: 5}}>
                    <Link to='/account' style={{textDecoration: 'none', marginTop:10}}>
                      <ArrowBackIcon color='primary' />
                    </Link>                    
                    <Divider variant="middle" />
                    <Autocomplete                      
                      size='small'
                      id="idPhone"
                      options={ searchByPhone}
                      getOptionLabel={(option) => option.Phone}
                      style={{ width: 180 }}
                      renderInput={(params) => <TextField {...params} id="Phone" name="Phone" type='text' label="--Phone Number-- "  variant="standard"  onSelect={onChangeHandlerInput } />}
                    /> 
                    <Divider variant="middle" />
                    <Button  
                        onClick={handlerSubmit}                              
                        type="butQua"
                         variant="outlined"
                         color="primary"
                         size="small"
                         startIcon={<SearchIcon />}                  
                        >
                         Search
                    </Button>                    
                    </Row>
                </Col>               
            </Row>
        </Container>   
        {
            filterPhone !== null &&
            filterPhone.map(x => 
            <Container style={{marginTop:'40px'}} key={x.id} >   
                <form>
            <Row className='mb-3'>
                <Col sm={3} className='text-center'> 
                <div >                    
                    <img               
                    style={{width: '300px', height:150}}
                    src={logo}
                   alt='..'  />                   
                </div>
                 </Col>
                 <Col sm={6} className="text-center" > 
                <h2 className="text-center">{businessname}</h2>
                <p className="text-center">{bstreet}<br/>
                {bcity}<br/>{bphone}</p>
            </Col>
                <Col sm={3} className="text-center"> 
                    <h3 >Estimate</h3>
                </Col>
            </Row>
            <br/>
            <Divider variant="middle" />
            <br/>
            <Row className='mb-3'>        
                <Col md={4}> 
                  <Row>
                    <Col md={4}>
                      <h5 style={{marginTop:12}}>Bill To:</h5>
                    </Col>
                    <Col >
                    <TextField  id="Name" name='Name' type='text' label="Full Name" readOnly  value={x.Name} variant="standard" />
                    </Col>
                  </Row>
                  <Row>
                    <Col  md={4}>
                      <h5 style={{marginTop:12}}>Phone:</h5>
                    </Col>
                    <Col>
                    <TextField id="Phone" name="Phone" type='number' label="Phone Number" variant="standard" readOnly  value={x.Phone} />
                    </Col>
                  </Row>
                  {/* <h4>Bill To:</h4>                
                  <TextField  id="Name" name='Name' type='text' label="Full Name" variant="standard" />
                  <TextField id="Phone" name="Phone" type='number' label="Phone Number" variant="standard" /> */}
                </Col>                
                <Col md={{ span: 4, offset: 4 }}> 
                    <Row>
                        <Col md={6}>
                        <h5>Estimate No:</h5>
                        </Col>
                        <Col md={6}>
                          <h4>{x.Idinvoice}</h4>
                          {/* <Getidinvoice /> */}
                        {/* <TextField InputProps={{ disableUnderline: true }} id="Idinvoice" name="Idinvoice" type='number'  variant="standard" readOnly  value={x.Idinvoice} /> */}
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                        <h5>Date:</h5>
                        </Col>
                        <Col md={6}>
                        <TextField  id="Date" name="Date" type='date'  variant="standard" readOnly  value={x.Date} />
                        </Col>
                    </Row>
                </Col>
                
            </Row>
            
            <br/>
            <Divider variant="middle" />
            <br/>
            <h4>Project Information</h4>
            <Row className='mb-3'>            
            <Col md={6}>
                <Row>
                    <Col md={3} >
                    <br/>
                    <h5>Address:</h5>                            
                    </Col>
                    <Col md={9}>
                    <TextField fullWidth id="Location" name='Location' type='text' label="Address" value={x.Address} variant="standard" />
                    </Col>
                </Row>
                <Row>
                    <Col md={3} >
                    <br/>
                    <h5 style={{ fontSize:17 }}>Due Date:</h5>                            
                    </Col>
                    <Col md={9}>
                    <TextField fullWidth style={{ marginTop:17 }} size='small'  id="Ddate" name="Ddate" type='datetime-local' value={x.Datedue} variant="standard" />
                    </Col> 
                </Row>                                 
            </Col>           
        </Row>
            <Row className='mb-5' >
            <table className="table table-sm responsive">
                <thead>
                <tr className='text-center'>
                <th style={{width:'20px', borderBottomColor:'black'}}>#</th>
                <th style={{width:'500px', borderBottomColor:'black'}}>Description</th>
                <th style={{width:'80px', borderBottomColor:'black'}}>Qty</th>
                <th style={{width:'90px', borderBottomColor:'black'}}>Price $</th>
                <th style={{width:'100px', borderBottomColor:'black'}}>Total $</th>
              </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{verticalAlign: 'middle' }} className='text-center'>1</td>
                    <td><TextField multiline  fullWidth={true} InputProps={{ disableUnderline: true }} inputProps={{ style: { textAlign: 'justify' }}} id="Des1" name="Des" type='text' label="" variant="standard"            readOnly  value={x.Lines_details[0].Des} /></td>
                    <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Qua1" name="Qua" type='number' label="" variant="standard" readOnly  value={x.Lines_details[0].Qua} /></td>
                    <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center'}}} id="Pri1" name="Pri" type='decimal' label="" variant="standard"  readOnly  value={x.Lines_details[0].Pri} /></td>
                    <td style={{verticalAlign: 'middle' }} className='text-center' >{((x.Lines_details[0].Pri ?  parseFloat(x.Lines_details[0].Pri) * parseFloat(x.Lines_details[0].Qua) : 0) ).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</td>
                  </tr>
                  <tr>
                    <td style={{verticalAlign: 'middle' }} className='text-center'>2</td>
                    <td><TextField multiline fullWidth={true} InputProps={{ disableUnderline: true }} inputProps={{ style: { textAlign: 'justify' }}}  id="Des2" name="Des" type='text' label="" variant="standard"            readOnly  value={x.Lines_details[1].Des}  /></td>
                    <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Qua2" name="Qua" type='number' label="" variant="standard" readOnly  value={x.Lines_details[1].Qua}  /></td>
                    <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Pri2" name="Pri" type='decimal' label="" variant="standard" readOnly  value={x.Lines_details[1].Pri}  /></td>
                    <td style={{verticalAlign: 'middle' }} className='text-center' >{((x.Lines_details[1].Pri ?  parseFloat(x.Lines_details[1].Pri) * parseFloat(x.Lines_details[1].Qua) : 0)).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</td>
                  </tr>
                  <tr>
                    <td style={{verticalAlign: 'middle' }} className='text-center' >3</td>
                    <td ><TextField multiline fullWidth={true} InputProps={{ disableUnderline: true }} inputProps={{ style: { textAlign: 'justify' }}}  id="Des3" name="Des" type='text' label="" variant="standard"           readOnly  value={x.Lines_details[2].Des}  /></td>
                    <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Qua3" name="Qua" type='number' label="" variant="standard" readOnly  value={x.Lines_details[2].Qua}  /></td>
                    <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Pri3" name="Pri" type='decimal' label="" variant="standard" readOnly  value={x.Lines_details[2].Pri}  /></td>
                    <td style={{verticalAlign: 'middle' }} className='text-center'>{((x.Lines_details[2].Pri ?  parseFloat(x.Lines_details[2].Pri) * parseFloat(x.Lines_details[2].Qua) : 0) ).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</td>
    
                  </tr> 
                 
                    <tr >
                    <td style={{verticalAlign: 'middle' }} className='text-center'>4</td>
                    <td><TextField multiline fullWidth={true} InputProps={{ disableUnderline: true }} inputProps={{ style: { textAlign: 'justify' }}}  id="Des4" name="Des" type='text' label="" variant="standard"            readOnly  value={x.Lines_details[3].Des}     /></td>
                    <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Qua4" name="Qua" type='number' label="" variant="standard" readOnly  value={x.Lines_details[3].Qua}     /></td>
                    <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Pri4" name="Pri" type='decimal' label="" variant="standard" readOnly  value={x.Lines_details[3].Pri}     /></td>
                    <td style={{verticalAlign: 'middle' }} className='text-center'>{((x.Lines_details[3].Pri ?  parseFloat(x.Lines_details[3].Pri) * parseFloat(x.Lines_details[3].Qua) : 0)).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</td>
                  </tr> 
                  
                  <tr >
                    <td style={{verticalAlign: 'middle' }} className='text-center'>5</td>
                    <td><TextField multiline fullWidth={true} InputProps={{ disableUnderline: true }} inputProps={{ style: { textAlign: 'justify' }}} id="Des5" name="Des" type='text' label="" variant="standard"             readOnly  value={x.Lines_details[4].Des}    /></td>
                    <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Qua5" name="Qua" type='number' label="" variant="standard"  readOnly  value={x.Lines_details[4].Qua}    /></td>
                    <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Pri5" name="Pri" type='decimal' label="" variant="standard"  readOnly  value={x.Lines_details[4].Pri}    /></td>
                    <td style={{verticalAlign: 'middle' }} className='text-center'>{((x.Lines_details[4].Pri ?  parseFloat(x.Lines_details[4].Pri) * parseFloat(x.Lines_details[4].Qua) : 0)).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</td>
                  </tr> 
                 
                   <tr >
                    <td style={{verticalAlign: 'middle' }} className='text-center'>6</td>
                    <td><TextField multiline fullWidth={true} InputProps={{ disableUnderline: true }} inputProps={{ style: { textAlign: 'justify' }}}  id="Des6" name="Des" type='text' label="" variant="standard"             readOnly  value={x.Lines_details[5].Des}   /></td>
                    <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Qua6" name="Qua" type='number' label="" variant="standard"  readOnly  value={x.Lines_details[5].Qua}   /></td>
                    <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Pri6" name="Pri" type='decimal' label="" variant="standard"  readOnly  value={x.Lines_details[5].Pri}   /></td>
                    <td style={{verticalAlign: 'middle' }} className='text-center'>{((x.Lines_details[5].Pri ?  parseFloat(x.Lines_details[5].Pri) * parseFloat(x.Lines_details[5].Qua) : 0)).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</td>
                  </tr>                     
                  <tr>
                    <td className='text-center' style={{borderColor:'white'}} ></td>
                    <td style={{borderColor:'white'}}></td>
                    <td style={{borderBottomColor:'white'}}></td>
                    <td style={{borderColor:'black'}} className='text-left' ><h6><strong>Sub Total $</strong></h6></td>
                    <td style={{borderColor:'black', verticalAlign:'middle'}} className='text-center' >
                      <h6>
                        <strong>
                            {
                             (((  x.Lines_details[0].Pri ?  parseFloat(x.Lines_details[0].Pri) * parseFloat(x.Lines_details[0].Qua) : 0)) + 
                             ((   x.Lines_details[1].Pri ?  parseFloat(x.Lines_details[1].Pri) * parseFloat(x.Lines_details[1].Qua) : 0)) +
                             ((   x.Lines_details[2].Pri ?  parseFloat(x.Lines_details[2].Pri) * parseFloat(x.Lines_details[2].Qua) : 0)) + 
                             ((   x.Lines_details[3].Pri ?  parseFloat(x.Lines_details[3].Pri) * parseFloat(x.Lines_details[3].Qua) : 0)) +
                             ((   x.Lines_details[4].Pri ?  parseFloat(x.Lines_details[4].Pri) * parseFloat(x.Lines_details[4].Qua) : 0)) + 
                             ((   x.Lines_details[5].Pri ?  parseFloat(x.Lines_details[5].Pri) * parseFloat(x.Lines_details[5].Qua) : 0))).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
                           }
                           </strong>
                      </h6>
                      {/* <strong>
                    <TextField inputProps={{min: 0, style: { textAlign: 'center', fontWeight: 'bold', fontSize: "large" }}} id="Balance" name="Balance"  type='text' label="" variant="standard" 
                    readOnly  value=  {
                      (((  x.Lines_details[0].Pri ?  parseFloat(x.Lines_details[0].Pri) * parseFloat(x.Lines_details[0].Qua) : 0)) + 
                      ((   x.Lines_details[1].Pri ?  parseFloat(x.Lines_details[1].Pri) * parseFloat(x.Lines_details[1].Qua) : 0)) +
                      ((   x.Lines_details[2].Pri ?  parseFloat(x.Lines_details[2].Pri) * parseFloat(x.Lines_details[2].Qua) : 0)) + 
                      ((   x.Lines_details[3].Pri ?  parseFloat(x.Lines_details[3].Pri) * parseFloat(x.Lines_details[3].Qua) : 0)) +
                      ((   x.Lines_details[4].Pri ?  parseFloat(x.Lines_details[4].Pri) * parseFloat(x.Lines_details[4].Qua) : 0)) + 
                      ((   x.Lines_details[5].Pri ?  parseFloat(x.Lines_details[5].Pri) * parseFloat(x.Lines_details[5].Qua) : 0))).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
                    }
                    /></strong>                   */}
                    </td>
                  </tr>   
                  <tr>
                <td className='text-center' style={{borderColor:'white'}} ></td>
                <td style={{borderColor:'white'}}></td>
                <td style={{borderBottomColor:'white'}}></td>
                <td style={{borderColor:'black'}} className='text-left' ><h6><TextField fullWidth id="Taxes" name='Taxes' type='decimal' label="Tax rate %" variant="standard" value={x.Taxesporcentaje} /></h6></td>
                <td style={{borderColor:'black'}} className='text-center' >
                    <h6>
                      <br></br>
                    <strong >
                    {
                      parseFloat(x.Taxes).toFixed(3).replace(/\d(?=(\d{3})+\.)/g, '$&,')

                    }
                    </strong>
                    </h6>
                </td>
              </tr>   
                  {/* <tr>
                    <td className='text-center' style={{borderColor:'white'}}></td>
                    <td style={{borderColor:'white'}} ></td>
                    <td style={{borderBottomColor:'white'}} ></td>
                    <td style={{borderColor:'black'}} className='text-left'><h6><strong>Paid Amount $</strong></h6></td>
                    <td style={{borderColor:'black'}} className='text-center' ><strong>
                      <TextField inputProps={{min: 0, style: { textAlign: 'center' }}}  
                      id="amo" type='number' label="" 
                      name="amo"
                      placeholder="0.00"
                      variant="standard"  
                      readOnly  value={x.Amount ? parseFloat(x.Amount).toFixed(2) : '0.00'}
                      /></strong></td>
                  </tr>  */}
                  <tr>
                    <td className='text-center' style={{borderColor:'white'}}></td>
                    <td style={{borderColor:'white'}}>
                    {/* <TextField InputProps={{ disableUnderline: true }} id="Payment" name="Payment" type='text' label="Payment methodo" variant="standard" readOnly  value={x.Payment} /> */}
                        {/* <Autocomplete
                          id="idpayment"
                          options={ dbdata.payment}
                          getOptionLabel={(option) => option.title}
                          style={{ width: 160 }}
                          renderInput={(params) => <TextField {...params} id="Payment" name="Payment" type='text' label="Payment methodo" variant="standard" readOnly  value={x.Payment} />
                        }
                        />  */}
                        </td>
                    <td style={{borderBottomColor:'white'}}></td>
                    <td style={{borderColor:'black', verticalAlign:'middle'}} className='text-left'><h6><strong>Total $</strong></h6></td>
                    <td style={{borderColor:'black', verticalAlign:'middle'}} className='text-center' ><strong>
                    <TextField inputProps={{min: 0, style: { textAlign: 'center', fontWeight: 'bold', fontSize: "large" }}} id="Balance" name="Balance"  type='text' label="" variant="standard" 
                    readOnly  value=  {parseFloat(x.Total).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                    /></strong>                  
                    </td>
                  </tr>  
                </tbody>
                </table>
            </Row>       
            <Row className='mb-2'>
                <Col md={6} className='text-center'>
                    {/* <h5>Customer signature</h5> */}
                </Col>
                <Col md={6} className='text-center'>
                    <h5>{businessname}</h5>                
                </Col>
            </Row>    
            <Row className='mb-5'>           
                <Col md={12} className='text-left'>
                    <h6>NOTES / SPECIAL REQUEST(S):</h6>
                    {/* <textarea  
                      style={{ fontSizeAdjust:15, width:'100%', height:'150px',  padding: "12px 20px", borderColor:'white',  fontSize: "16px", resize: "none" }}
                       id="Recommend" 
                       name="Recommend" 
                       type='text' 
                    />    */}
                    <textarea  
                      style={{ borderColor:'white', width:'100%',  height:'100px', resize:'vertical'}}
                       id="Recommend" 
                       name="Recommend" 
                       type='text' 
                       readOnly  value={x.Recommend}
                    />               
                      
                </Col>
            </Row> 
            <Row>
                <Col md={4} className='text-center' >
                    <span><strong>{bemail}</strong></span>
                </Col>
                <Col md={4} className='text-center'>
                    <h5 ><strong>Thanks for choosing us.</strong></h5>
                </Col>
                <Col md={4} >
                <Row>
                <Col>                  
                </Col>
                <Col>
                    <Button    
                           onClick={print}  
                            variant="outlined"
                            color="secondary"
                            size="small"
                            className="primary"
                            startIcon={<PrintIcon />}                  
                           >
                            Print
                       </Button>
                </Col>

              </Row>
                </Col>
            </Row>
            </form>
          </Container>
            )
        }
        </> 
        
    )
}

export default SearchEstimate