import React, {useState, useEffect} from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import * as  invoicesActions from '../store/actions/estimate'
import Axios from 'axios'
import { Link } from 'react-router-dom';
import './InvoiceLast.css'
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Divider } from '@material-ui/core';
import { Button } from '@material-ui/core';
import PrintIcon from '@mui/icons-material/Print';
import SaveIcon from '@material-ui/icons/Save';
import dbdata from "../db/dbdata.json"
import Getidinvoice from '../components/Getidinvoice';
import {businessname, bstreet, bcity, bemail, bphone} from '../models/European'
import logo from '../assets/image/logo.png'

 
const  Estimate = () => { 
  
  const searchByPhone = useSelector(state => state.estimates.estimates)

  const [idinvoice, setidinvoice] = useState('')
  const [name,      setname] = useState('')
  const [phone,     setphone] = useState('')
  const [date,      setdate] = useState('')
  const [datedue,      setdatedue] = useState('')
  const [address,      setaddress] = useState('')
  const [balance,   setbalance] = useState('')
  const [payment,   setpayment] = useState('')
  const [recommend, setrecommend] = useState('')
     
      const [pri1, setpri1] = useState(0)
      const [qua1, setqua1] = useState(0)
      const [des1, setdes1] = useState('')
      const [pri2, setpri2] = useState(0)
      const [qua2, setqua2] = useState(0)
      const [des2, setdes2] = useState('')
      const [pri3, setpri3] = useState(0)
      const [qua3, setqua3] = useState(0)
      const [des3, setdes3] = useState('')
      const [pri4, setpri4] = useState(0)
      const [qua4, setqua4] = useState(0)
      const [des4, setdes4] = useState('')
      const [pri5, setpri5] = useState(0)
      const [qua5, setqua5] = useState(0)
      const [des5, setdes5] = useState('')
      const [pri6, setpri6] = useState(0)
      const [qua6, setqua6] = useState(0)
      const [des6, setdes6] = useState('')
      const [amo , setamo]  = useState(0)
      const [taxes , settaxes]  = useState(0)
      
     function myChangeHandleridinvoice(event) { setidinvoice(event.target.value)}
     function myChangeHandlername(event) { setname(event.target.value)}
     function myChangeHandlerphone(event) { setphone(event.target.value)}     
     function myChangeHandlerdate(event) { setdate(event.target.value)}
     function myChangeHandlerdatedue(event) { setdatedue(event.target.value)}
     function myChangeHandleraddress(event) { setaddress(event.target.value)}
     function myChangeHandlerbalance() { 
       
      setbalance(
        
          ((((pri1 ?  parseInt(pri1) * parseInt(qua1) : 0)) + 
            ((pri2 ?  parseInt(pri2) * parseInt(qua2) : 0)) +
            ((pri3 ?  parseInt(pri3) * parseInt(qua3) : 0)) + 
            ((pri4 ?  parseInt(pri4) * parseInt(qua4) : 0)) + 
            ((pri5 ?  parseInt(pri5) * parseInt(qua5) : 0)) + 
            ((pri6 ?  parseInt(pri6) * parseInt(qua6) : 0))) - (amo ? parseInt(amo) : 0)).toFixed(2)
      )
    
    }
     function myChangeHandlerpayment(event) { setpayment(event.target.value)}
     function myChangeHandlerrecommend(event) { setrecommend(event.target.value)}

     function myChangeHandlerpri1(event) { setpri1(event.target.value)}
     function myChangeHandlerqua1(event) { setqua1(event.target.value)}     
     function myChangeHandlerdes1(event) { setdes1(event.target.value)}      
     function myChangeHandlerpri2(event) { setpri2(event.target.value)}
     function myChangeHandlerqua2(event) { setqua2(event.target.value)}
     function myChangeHandlerdes2(event) { setdes2(event.target.value)}  
     function myChangeHandlerpri3(event) { setpri3(event.target.value)}
     function myChangeHandlerqua3(event) { setqua3(event.target.value)}
     function myChangeHandlerdes3(event) { setdes3(event.target.value)} 
     function myChangeHandlerpri4(event) { setpri4(event.target.value)}
     function myChangeHandlerqua4(event) { setqua4(event.target.value)}
     function myChangeHandlerdes4(event) { setdes4(event.target.value)}  
     function myChangeHandlerpri5(event) { setpri5(event.target.value)}
     function myChangeHandlerqua5(event) { setqua5(event.target.value)}
     function myChangeHandlerdes5(event) { setdes5(event.target.value)}  
     function myChangeHandlerpri6(event) { setpri6(event.target.value)}
     function myChangeHandlerqua6(event) { setqua6(event.target.value)}
     function myChangeHandlerdes6(event) { setdes6(event.target.value)}  
     function myChangeHandleramo(event)  { setamo(event.target.value)}
     function myChangeHandlertaxes(event)  { 
        settaxes((event.target.value / 100))
    }

    

     function print(){
         window.print()
       }

       const authentication = (e) => {          
        e.preventDefault()
        const form = e.target    
       const data = {
        "Idinvoice": searchByPhone.length > 1 ? parseInt(searchByPhone[searchByPhone.length - 1].Idinvoice) + 1 : '',
        "Name":      name,
        "Phone":     phone,
        "Date":      date,
        "Taxes":    ((((   pri1 ?  parseFloat( pri1) * parseFloat(qua1) : 0)) + 
                      ((   pri2 ?  parseFloat( pri2) * parseFloat(qua2) : 0)) +
                      ((   pri3 ?  parseFloat( pri3) * parseFloat(qua3) : 0)) + 
                      ((   pri4 ?  parseFloat( pri4) * parseFloat(qua4) : 0)) +
                      ((   pri5 ?  parseFloat( pri5) * parseFloat(qua5) : 0)) + 
                      ((   pri6 ?  parseFloat( pri6) * parseFloat(qua6) : 0))) * (taxes ? parseFloat(taxes) : 0)).toFixed(3),
        "Total" :    (
                    ((((   pri1 ?  parseFloat(   pri1) * parseFloat(   qua1) : 0)) + 
                      ((   pri2 ?  parseFloat(   pri2) * parseFloat(   qua2) : 0)) +
                      ((   pri3 ?  parseFloat(   pri3) * parseFloat(   qua3) : 0)) + 
                      ((   pri4 ?  parseFloat(   pri4) * parseFloat(   qua4) : 0)) +
                      ((   pri5 ?  parseFloat(   pri5) * parseFloat(   qua5) : 0)) + 
                      ((   pri6 ?  parseFloat(   pri6) * parseFloat(   qua6) : 0))) * (taxes ? parseFloat(taxes) : 0) ) +
                      ((((  pri1 ? parseFloat(   pri1) * parseFloat(   qua1) : 0)) + 
                      ((   pri2 ?  parseFloat(   pri2) * parseFloat(   qua2) : 0)) +
                      ((   pri3 ?  parseFloat(   pri3) * parseFloat(   qua3) : 0)) + 
                      ((   pri4 ?  parseFloat(   pri4) * parseFloat(   qua4) : 0)) + 
                      ((   pri5 ?  parseFloat(   pri5) * parseFloat(   qua5) : 0)) + 
                      ((   pri6 ?  parseFloat(   pri6) * parseFloat(   qua6) : 0))) - (   amo ? parseFloat(   amo) : 0))
                      ).toFixed(2),      
        "Amount":   amo,
        "Datedue":   datedue,
        "Payment":   payment,
        "Recommend": recommend,
        "Address" : address,
        "Taxesporcentaje" : (taxes * 100).toFixed(3),
          "Lines_details": [         
              {
                  "Des": des1,
                  "Pri": pri1,
                  "Qua": qua1,
              },
              { 
                  "Des": des2,
                  "Pri": pri2,
                  "Qua": qua2,
              },
              {
                "Des": des3,
                "Pri": pri3,
                "Qua": qua3,
              },
              {
                "Des": des4,
                "Pri": pri4,
                "Qua": qua4,
              },
              {
                "Des": des5,
                "Pri": pri5,
                "Qua": qua5,
              },
              {
                "Des": des6,
                "Pri": pri6,
                "Qua": qua6,
              }
          ]
      }  

      Axios.post(process.env.REACT_APP_FETCH_ESTIMATE, data)
       .then(r => {
         localStorage.setItem('token', r.data.token)
         window.location = "/"
       }).then(() => alert('The Estimate has been succesfully saved'))
       .catch(e => {
         alert('error al iniciar sesion')
       } )
      }

    return (
        <>
        <Container style={{marginTop:'40px'}}>   
        <Row className='mb-3'>
            <Col sm={3} className='text-center'> 
            <div >
                <Link to='/account'>
                 <img               
                style={{width: '300px', height:200}}
                src={logo}
               alt='..'  /> 
                </Link>
            </div>
             </Col>
            <Col sm={6} className="text-center" > 
            <h2 className="text-center">{businessname}</h2>
                <p className="text-center">{bstreet}<br/>
                {bcity}<br/>{bphone}</p>
            </Col>
            <Col sm={3} className="text-center"> 
            <h3 >Estimate</h3>
            </Col>
        </Row>
             <br/>
            <Divider variant="middle" />
            <br/>
        <Row className='mb-3'>        
            <Col md={4}> 
              <Row>
                <Col md={4}>
                  <h5 style={{marginTop:12}}>Bill To:</h5>
                </Col>
                <Col >
                <TextField fullWidth id="Name" name='Name' type='text' label="Full Name" variant="standard" onChange={myChangeHandlername }/>
                </Col>
              </Row>
              <Row>
                <Col  md={4}>
                  <h5 style={{marginTop:12}}>Phone:</h5>
                </Col>
                <Col>
                <TextField fullWidth id="Phone" name="Phone" type='number' label="Phone Number" variant="standard" onChange={myChangeHandlerphone} />
                </Col>
              </Row>
              {/* <h4>Bill To:</h4>                
              <TextField  id="Name" name='Name' type='text' label="Full Name" variant="standard" />
              <TextField id="Phone" name="Phone" type='number' label="Phone Number" variant="standard" /> */}
            </Col>
            <Col md={{ span: 4, offset: 4 }}> 
              <form>
                <Row>
                    <Col md={6}>
                    <h5>Estimate No:</h5>
                    </Col>
                    <Col md={6}>
                      <h4>{ searchByPhone.length > 1 ? parseInt(searchByPhone[searchByPhone.length - 1].Idinvoice) + 1 : '' }</h4>
                      {/* <Getidinvoice /> */}
                    {/* <TextField fullWidth InputProps={{ disableUnderline: true }} id="Idinvoice" name="Idinvoice" type='number'  variant="standard" onChange={myChangeHandleridinvoice} /> */}
                    </Col>
                </Row>
                </form>
                <Row>
                    <Col md={6}> 
                    <h5>Date:</h5>
                    </Col>
                    <Col md={6}>
                    <TextField fullWidth   id="Date" name="Date" type='date'  variant="standard" onChange={myChangeHandlerdate} />
                    </Col>
                </Row>
            </Col>
            
        </Row>
        <br/>
        <Divider variant="middle" />
        <br/>
        <h4>Project information</h4>
        <Row className='mb-3'>            
            <Col md={6}>
                <Row>
                    <Col md={3} >
                    <br/>
                    <h5>Address:</h5>                            
                    </Col>
                    <Col  md={9} >
                    <TextField fullWidth id="Address" name='Address' type='text' label="Address" variant="standard" onChange={myChangeHandleraddress} />
                    </Col>
                </Row>
                <Row>
                    <Col md={3} >
                    <br/>
                    <h5 style={{ fontSize:17 }}>Due Date:</h5>                            
                    </Col>
                    <Col md={9}>
                    <TextField fullWidth style={{  marginTop:17 }} size='small'  id="Duedate" name="Duedate" type='datetime-local'  variant="standard" onChange={myChangeHandlerdatedue}/>
                    </Col> 
                </Row>                                 
            </Col>           
        </Row>
        <Row className='mb-5' >
        <table className="table table-sm responsive">
            <thead>
              <tr className='text-center'>
                <th style={{width:'20px', borderBottomColor:'black'}}>#</th>
                <th style={{width:'500px', borderBottomColor:'black'}}>Description</th>
                <th style={{width:'80px', borderBottomColor:'black'}}>Qty</th>
                <th style={{width:'90px', borderBottomColor:'black'}}>Price $</th>
                <th style={{width:'100px', borderBottomColor:'black'}}>Total $</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{verticalAlign: 'middle' }} className='text-center'>1</td>
                <td><div><TextField  multiline  fullWidth={true} InputProps={{ disableUnderline: true }} inputProps={{ style: { textAlign: 'justify' }}}  id="Des1" name="Des" type='text' label="" variant="standard" onChange={myChangeHandlerdes1} /></div></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{ min: 0, style: { textAlign: 'center' }}} id="Qua1" name="Qua" type='number' label="" variant="standard" onChange={ myChangeHandlerqua1} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{ min: 0, style: { textAlign: 'center'}}} id="Pri1" name="Pri" type='decimal' label="" variant="standard" onChange={ myChangeHandlerpri1} /></td>
                <td style={{verticalAlign: 'middle' }} className='text-center' >{((   pri1 ?  parseFloat(   pri1) * parseFloat(   qua1) : 0)).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</td>
              </tr>
              <tr>
                <td style={{verticalAlign: 'middle' }} className='text-center'>2</td>
                <td><TextField multiline maxRows={20} fullWidth={true} InputProps={{ disableUnderline: true }} inputProps={{ style: { textAlign: 'justify' }}}  id="Des2" name="Des" type='text' label="" variant="standard" onChange={myChangeHandlerdes2} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Qua2" name="Qua" type='number' label="" variant="standard" onChange={ myChangeHandlerqua2} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Pri2" name="Pri" type='decimal' label="" variant="standard" onChange={ myChangeHandlerpri2} /></td>
                <td style={{verticalAlign: 'middle' }} className='text-center' >{((   pri2 ?  parseFloat(   pri2) * parseFloat(   qua2) : 0)).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</td>
              </tr>
              <tr>
                <td style={{verticalAlign: 'middle' }} className='text-center' >3</td>
                <td ><TextField multiline maxRows={20} fullWidth={true} InputProps={{ disableUnderline: true }} inputProps={{ style: { textAlign: 'justify' }}}  id="Des3" name="Des" type='text' label="" variant="standard" onChange={myChangeHandlerdes3} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Qua3" name="Qua" type='number' label="" variant="standard" onChange={ myChangeHandlerqua3} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Pri3" name="Pri" type='decimal' label="" variant="standard" onChange={ myChangeHandlerpri3} /></td>
                <td style={{verticalAlign: 'middle' }} className='text-center'>{((   pri3 ?  parseFloat(   pri3) * parseFloat(   qua3) : 0) ).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</td>

              </tr> 
             
                <tr >
                <td style={{verticalAlign: 'middle' }} className='text-center'>4</td>
                <td><TextField multiline maxRows={20} fullWidth={true} InputProps={{ disableUnderline: true }} inputProps={{ style: { textAlign: 'justify' }}}  id="Des4" name="Des" type='text' label="" variant="standard"  onChange={myChangeHandlerdes4} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Qua4" name="Qua" type='number' label="" variant="standard" onChange={ myChangeHandlerqua4} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Pri4" name="Pri" type='decimal' label="" variant="standard"  onChange={ myChangeHandlerpri4} /></td>
                <td style={{verticalAlign: 'middle' }} className='text-center'>{((   pri4 ?  parseInt(   pri4) * parseFloat(   qua4) : 0)).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</td>
              </tr> 
              
              <tr >
                <td style={{verticalAlign: 'middle' }} className='text-center'>5</td>
                <td><TextField multiline maxRows={20} fullWidth={true} InputProps={{ disableUnderline: true }} inputProps={{ style: { textAlign: 'justify' }}}  id="Des5" name="Des" type='text' label="" variant="standard" onChange={myChangeHandlerdes5} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Qua5" name="Qua" type='number' label="" variant="standard" onChange={ myChangeHandlerqua5} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Pri5" name="Pri" type='decimal' label="" variant="standard"  onChange={ myChangeHandlerpri5} /></td>
                <td style={{verticalAlign: 'middle' }} className='text-center'>{((   pri5 ?  parseInt(   pri5) * parseFloat(   qua5) : 0)).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</td>
              </tr> 
             
               <tr >
                <td style={{verticalAlign: 'middle' }} className='text-center'>6</td>
                <td><TextField multiline maxRows={20} fullWidth={true} InputProps={{ disableUnderline: true }} inputProps={{ style: { textAlign: 'justify' }}}  id="Des6" name="Des" type='text' label="" variant="standard"  onChange={myChangeHandlerdes6} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Qua6" name="Qua" type='number' label="" variant="standard" onChange={ myChangeHandlerqua6} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Pri6" name="Pri" type='decimal' label="" variant="standard"  onChange={ myChangeHandlerpri6} /></td>
                <td style={{verticalAlign: 'middle' }} className='text-center'>{((   pri6 ?  parseFloat(   pri6) * parseFloat(   qua6) : 0)).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</td>
              </tr>                     
              <tr>
                <td className='text-center' style={{borderColor:'white'}} ></td>
                <td style={{borderColor:'white'}}></td>
                <td style={{borderBottomColor:'white'}}></td>
                <td style={{borderColor:'black'}} className='text-left' ><h6><strong>Sub Total $</strong></h6></td>
                <td style={{borderColor:'black'}} className='text-center' >
                    <h6>
                    <strong >
                    {
                        (((  pri1 ? parseFloat(pri1) * parseFloat(qua1) : 0)) + 
                        ((   pri2 ?  parseFloat(pri2) * parseFloat(qua2) : 0)) +
                        ((   pri3 ?  parseFloat(pri3) * parseFloat(qua3) : 0)) + 
                        ((   pri4 ?  parseFloat(pri4) * parseFloat(qua4) : 0)) +
                        ((   pri5 ?  parseFloat(pri5) * parseFloat(qua5) : 0)) + 
                        ((   pri6 ?  parseFloat(pri6) * parseFloat(qua6) : 0))).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')

                    }
                    </strong>
                    </h6>
                </td>
              </tr>   
              <tr>
                <td className='text-center' style={{borderColor:'white'}} ></td>
                <td style={{borderColor:'white'}}></td>
                <td style={{borderBottomColor:'white'}}></td>
                <td style={{borderColor:'black'}} className='text-left' ><h6><TextField fullWidth id="Taxes" name='Taxes' type='decimal' label="Tax rate%" variant="standard" onChange={myChangeHandlertaxes} /></h6></td>
                <td style={{borderColor:'black'}} className='text-center' >
                    <h6>
                      <br></br>
                    <strong >
                    {
                      ((((   pri1 ?  parseFloat(   pri1) * parseFloat(   qua1) : 0)) + 
                        ((   pri2 ?  parseFloat(   pri2) * parseFloat(   qua2) : 0)) +
                        ((   pri3 ?  parseFloat(   pri3) * parseFloat(   qua3) : 0)) + 
                        ((   pri4 ?  parseFloat(   pri4) * parseFloat(   qua4) : 0)) +
                        ((   pri5 ?  parseFloat(   pri5) * parseFloat(   qua5) : 0)) + 
                        ((   pri6 ?  parseFloat(   pri6) * parseFloat(   qua6) : 0))) * (taxes ? parseFloat(taxes) : 0)).toFixed(3).replace(/\d(?=(\d{3})+\.)/g, '$&,')

                    }
                    </strong>
                    </h6>
                </td>
              </tr>   
              {/* <tr>
                <td className='text-center' style={{borderColor:'white'}}></td>
                <td style={{borderColor:'white'}} ></td>
                <td style={{borderBottomColor:'white'}} ></td>
                <td style={{borderColor:'black'}} className='text-left'><h6><strong>Paid Amount $</strong></h6></td>
                <td style={{borderColor:'black'}} className='text-center' ><strong>
                  <TextField inputProps={{min: 0, style: { textAlign: 'center' }}}  
                  id="amo" type='number' label="" 
                  name="amo"
                  placeholder="  0.00"
                  variant="standard"  
                  onChange={ myChangeHandleramo}/></strong></td>
              </tr>  */}
              <tr>
                <td className='text-center' style={{borderColor:'white'}}></td>
                <td style={{borderColor:'white'}}>
                  {/* <Autocomplete
                      id="idpayment"
                      options={ dbdata.payment}
                      getOptionLabel={(option) => option.title}
                      style={{ width: 160 }}
                      renderInput={(params) => <TextField {...params}  id="Payment" name="Payment" type='text' label="Payment methodo" variant="standard" onSelect={myChangeHandlerpayment} />}
                    />  */}
                    </td>
                <td style={{borderBottomColor:'white'}}></td>
                <td style={{borderColor:'black', verticalAlign:'middle'}} className='text-left'><h6><strong>Total $</strong></h6></td>
                <td style={{borderColor:'black', verticalAlign:'middle'}} className='text-center' ><strong>
                <TextField inputProps={{min: 0, style: { textAlign: 'center', fontWeight: 'bold', fontSize: "large" }}} id="Balance" name="Balance"  type='text' label="" variant="standard" 
                value= 
                {
                 (
                  ((((  pri1 ? parseFloat(   pri1) * parseFloat(   qua1) : 0)) + 
                  ((   pri2 ?  parseFloat(   pri2) * parseFloat(   qua2) : 0)) +
                  ((   pri3 ?  parseFloat(   pri3) * parseFloat(   qua3) : 0)) + 
                  ((   pri4 ?  parseFloat(   pri4) * parseFloat(   qua4) : 0)) +
                  ((   pri5 ?  parseFloat(   pri5) * parseFloat(   qua5) : 0)) + 
                  ((   pri6 ?  parseFloat(   pri6) * parseFloat(   qua6) : 0))) * (taxes ? parseFloat(taxes) : 0) ) +
                  ((((  pri1 ? parseFloat(   pri1) * parseFloat(   qua1) : 0)) + 
                  ((   pri2 ?  parseFloat(   pri2) * parseFloat(   qua2) : 0)) +
                  ((   pri3 ?  parseFloat(   pri3) * parseFloat(   qua3) : 0)) + 
                  ((   pri4 ?  parseFloat(   pri4) * parseFloat(   qua4) : 0)) + 
                  ((   pri5 ?  parseFloat(   pri5) * parseFloat(   qua5) : 0)) + 
                  ((   pri6 ?  parseFloat(   pri6) * parseFloat(   qua6) : 0))) - (   amo ? parseFloat(   amo) : 0))
                  ).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
              }
                /></strong>                  
                </td>
              </tr>  
            </tbody>
            </table>
        </Row>       
        <Row className='mb-2'>
            <Col md={6} className='text-center'>
                {/* <h5>Customer signature</h5> */}
            </Col>
            <Col md={6} className='text-center'>
                <h5>{businessname}</h5>                
            </Col>
        </Row>    
        <Row className='mb-5'>           
            <Col md={12} className='text-left'>
                <h6>NOTES / SPECIAL REQUEST(S):</h6>
                {/* <textarea  
                  style={{ fontSizeAdjust:15, width:'100%', height:'150px',  padding: "12px 20px", borderColor:'white',  fontSize: "16px", resize: "none" }}
                   id="Recommend" 
                   name="Recommend" 
                   type='text' 
                />    */}
                <textarea  
                  style={{ borderColor:'white', width:'100%',  height:'100px', resize:'vertical'}}
                   id="Recommend" 
                   name="Recommend" 
                   type='text' 
                   onChange={myChangeHandlerrecommend}
                />               
                  
            </Col>
        </Row> 
        <Row>
            <Col md={4} className='text-center' >
                <span><strong>{bemail}</strong></span>
            </Col>
            <Col md={4} className='text-center'>
                <h5 ><strong>Thanks for choosing us.</strong></h5>
            </Col>
            <Col md={4} >
            <Row>
                <Col>
                   <Button                                
                        type="submit"
                         variant="outlined"
                         color="primary"
                         size="small"
                         className="primary"
                         startIcon={<SaveIcon />}     
                         onClick={authentication}             
                        >
                         Save
                    </Button>
                </Col>
                <Col>
                    <Button    
                           onClick={ print}  
                            variant="outlined"
                            color="secondary"
                            size="small"
                            className="primary"
                            startIcon={<PrintIcon />}                  
                           >
                            Print
                       </Button>
                </Col>

              </Row>
            </Col>
        </Row>
      </Container>
      </>
)}

export default Estimate