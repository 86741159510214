import Estimate from "../../models/Estimate"
export const SET_ESTIMATES = 'SET_ESTIMATES'

export const fetchEstimates = () => {
    return async dispatch => {
        try {
          const response = await fetch(
            process.env.REACT_APP_FETCH_ESTIMATE
           );
      
           if (!response.ok){
               throw new Error('Something went wrong!')
           }
      
           const resData = await response.json()
           const loadedEstimates = [];
             for(const key in resData) {
                loadedEstimates.push(new Estimate(
                  key, 
                  resData[key].Balance, 
                  resData[key].Date,
                  resData[key].Datedue,
                  resData[key].Idinvoice,
                  resData[key].Lines_details,
                  resData[key].Name,
                  resData[key].Payment,
                  resData[key].Phone,
                  resData[key].Recommend,
                  resData[key].Address,
                  resData[key].Taxes,
                  resData[key].Total,
                  resData[key].Amount,
                  resData[key].Taxesporcentaje,
                     ))
             }
          dispatch({ type:SET_ESTIMATES, estimates: loadedEstimates });
        } catch (err) {
          throw err;
        }
         
        } 
}

