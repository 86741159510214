class Estimate {
  constructor(id, Balance, Date, Datedue, Idinvoice,
        Lines_details,  Name, Payment, Phone, Recommend, Address, Taxes, Total, Amount, Taxesporcentaje ) {
      this.id = id;
      this.Balance = Balance;
      this.Date = Date;
      this.Datedue = Datedue;
      this.Idinvoice = Idinvoice;
      this.Lines_details = Lines_details;
      this.Name = Name;
      this.Payment = Payment;
      this.Phone = Phone;
      this.Recommend = Recommend;
      this.Address = Address;
      this.Taxes = Taxes;
      this.Total = Total;
      this.Amount = Amount;
      this.Taxesporcentaje = Taxesporcentaje;
    }
    }
      
  export default Estimate;
  