import Invoice from "../../models/Invoice"
export const SET_INVOICES = 'SET_INVOICES'
// export const SET_INVOICE = 'SET_INVOICE'
export const SET_MAKES = 'SET_MAKES'
export const SET_MODELS = 'SET_MODELS'
export const CREATE_INVOICE = 'CREATE_INVOICE'


export const createInvoice = (data) => {

  return async dispatch => {
      //any async code you want!
     await fetch(process.env.REACT_APP_CREATEINVOICE, 
     {
       method:"POST",
       headers: {"content-type": "application/json"
     },
     body: JSON.stringify(data)
     })
     .then(() => 
         alert("Successfully updated")
         )
       .catch(() => 
          alert("Error updating field")
       )

      dispatch({ 
          type: CREATE_INVOICE, 
          invoice: data
      })    
  }
}

export const fetchInvoices = () => {
    return async dispatch => {
        try {
          const response = await fetch(
            process.env.REACT_APP_FETCH_INVOICES
           );
      
           if (!response.ok){
               throw new Error('Something went wrong!')
           }
      
           const resData = await response.json()
           const loadedInvoices = [];
             for(const key in resData) {
                loadedInvoices.push(new Invoice(
                     key, 
                     resData[key].Balance, 
                     resData[key].Date,
                     resData[key].Datedue,
                     resData[key].Idinvoice,
                     resData[key].Lines_details,
                     resData[key].Name,
                     resData[key].Payment,
                     resData[key].Phone,
                     resData[key].Recommend,
                     resData[key].Address,
                     resData[key].Taxes,
                     resData[key].Total,
                     resData[key].Amount,
                     resData[key].Taxesporcentaje,
                     ))
             }
          dispatch({ type:SET_INVOICES, invoices: loadedInvoices });
        } catch (err) {
          throw err;
        }
         
        } 
}

export const setMakes = items => {
    return {
        type: SET_MAKES, makes: items
    }
}
export const setModels = item => {
    return {
        type: SET_MODELS, models: item
    }
}